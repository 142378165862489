@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

@import "notify.css";
@import "common/datepicker.css";
@import "dashboard/dashboard.css";
@import "dashboard/settings.css";
@import "common/button.css";
@import "common/icon.css";
@import "common/table.css";
@import "common/multiselect.css";

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

/* Firefox */
/* * {
    scrollbar-width: thin;
    scrollbar-color: var(--secondary) var(--primary);
  } */

/* Chrome, Edge, and Safari */
/* *::-webkit-scrollbar {
    width: 15px;
}

*::-webkit-scrollbar-track {
    background: green;
    border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
    background-color: lime;
    border-radius: 14px;
    border: 3px solid red;
} */

@layer base {
    body {
        @apply font-poppins;
    }
}

.test {
    @apply text-red-600;
}
