.sidebar-link {
    @apply flex items-center rounded-2xl py-2.5 px-[18px] text-[22px] text-white font-light bg-transparent hover:bg-primary-1 hover:text-button-1 transition-all;
}

.router-link-active.sidebar-link,
.router-link-exact-active.sidebar-link {
    @apply !bg-primary-1 !text-button-1;
}

.submenu-link {
    @apply lg:!min-w-[121px] xs:min-w-[100px] lg:!h-[47px] xs:h-[40px] rounded-t-lg py-3 px-4 lg:!text-lg xs:text-sm font-medium bg-transparent text-primary-3 focus:outline-none hover:!bg-primary-3 hover:text-white text-center flex items-center justify-center transition-all;
}

.router-link-exact-active.submenu-link {
    @apply !bg-primary-3 !text-white;
}

.sidebar-link-icon {
    @apply fill-white transition-all;
}

.router-link-exact-active .sidebar-link-icon,
.router-link-active .sidebar-link-icon {
    @apply fill-primary-1;
}

/*aside*/
.aside {
    @apply w-auto overflow-y-scroll min-h-screen h-full fixed left-0 top-0 z-[60] bg-secondary-4 transition-all duration-500;
}
.aside::-webkit-scrollbar {
    @apply w-[4px];
}
.aside::-webkit-scrollbar-track {
    @apply bg-primary-3-400;
}
.aside::-webkit-scrollbar-thumb {
    @apply bg-primary-1-600 rounded-3xl;
}

.aside-mask {
    @apply absolute w-[55px]  left-0 bg-transparent group-hover:bg-secondary-4 z-10 group-hover:shadow-[0px_3px_6px_#00000029] transition-all duration-500;
    @apply h-[calc(100%-85px)] top-[120px];
}
.aside-mask-fixed {
    @apply bg-secondary-4 z-10 shadow-[0px_3px_6px_#00000029];
}

.aside__main {
    @apply h-full py-5  transition-all duration-500;
}

.aside-menu__item {
    @apply mb-[8px] last:mb-0  transition-all duration-500;
}

.aside__logo {
    @apply w-[55px] h-[55px] flex items-center justify-center p-[10px] bg-secondary-4 z-20  transition-all duration-500;
    @apply flex items-center group-hover:pr-[2px];
}
.aside__logo-fixed {
    @apply pr-[2px];
}

.aside__logo-img {
    @apply w-full transition-all duration-500;
}

.aside__logo-img2 {
    @apply z-10 h-[55px];
    @apply -ml-[166px] opacity-0 invisible;
    @apply group-hover:ml-0 group-hover:opacity-100 group-hover:visible transition-all duration-500;
}
.aside__logo-img2-fixed {
    @apply ml-0 opacity-100 visible;
}

/*.aside-menu__item{*/
/*    @apply w-[55px] h-[55px] p-4;*/
/*}*/

.aside-menu__item-link {
    @apply flex items-center relative  transition-all duration-500;
}

.router-link-active.aside-menu__item-link .aside-menu__item-icon,
.router-link-exact-active.aside-menu__item-link .aside-menu__item-icon {
    @apply group-hover:bg-primary-1  transition-all duration-500;
}
.router-link-active.aside-menu__item-link .aside-menu__item-icon-fixed,
.router-link-exact-active.aside-menu__item-link .aside-menu__item-icon-fixed {
    @apply bg-primary-1;
}

.aside-menu__item-icon {
    @apply w-[55px] h-[55px] p-4 z-20 bg-secondary-4 transition-all duration-500;
}

.aside-menu__item-icon svg {
    @apply fill-primary-1  transition-all duration-500;
}

.router-link-active.aside-menu__item-link .aside-menu__item-icon svg,
.router-link-exact-active.aside-menu__item-link .aside-menu__item-icon svg {
    @apply fill-white group-hover:fill-primary-3 transition-all duration-500;
}
.router-link-active.aside-menu__item-link .aside-menu__item-icon-fixed svg,
.router-link-exact-active.aside-menu__item-link
    .aside-menu__item-icon-fixed
    svg {
    @apply fill-primary-3;
}

.aside-menu__item--title {
    @apply -ml-[300px] opacity-0 invisible;
    @apply whitespace-nowrap z-[2] transition-all duration-500 px-5 text-white/80 font-light;
    @apply group-hover:ml-0 group-hover:opacity-100 group-hover:visible;
    @apply hover:bg-primary-1 hover:text-primary-3 flex-1 py-2;
}
.aside-menu__item--title-fixed {
    @apply ml-0 opacity-100 visible;
}
.router-link-active.aside-menu__item-link .aside-menu__item--title,
.router-link-exact-active.aside-menu__item-link .aside-menu__item--title {
    @apply text-white font-medium hover:bg-transparent transition-all;
}

/* mobile side bar */
