.settings__summary__table tr th,
.settings__summary__table tr td {
    @apply pb-5 pr-2  max-w-3xl  align-top;
}

.settings__summary__table th {
    @apply lg:!text-lg xxs:text-sm font-medium text-primary-3;
}

.settings__summary__table td {
    @apply !text-lg xxs:text-sm font-normal text-primary-3;
}
