.field {
    @apply relative after:absolute after:right-[14px] after:content-['\F0B66'] after:font-mdi after:text-lg after:text-primary-3-200 after:z-10
}

.field-input {
    @apply bg-white border !border-secondary-2 focus:!border-red-600 !h-[50px] !rounded-2xl;
    @apply cursor-pointer transition-all relative w-full !pl-3 !pr-16 outline-none text-sm z-0;
}

.is-focused .field-input {
    @apply !border-red-600;
}

.field-clear-button {
    @apply !absolute !right-[40px] !top-0 !bottom-0 my-auto mx-0;
}

.custom-button-content {
    @apply !leading-[normal];
}

.field.is-disabled .field-input {
    @apply !border-secondary-2/20 !bg-secondary-2/20;
}

.vc-popover-content-wrapper {
    @apply !z-20;
}

.vc-am-pm button {
    @apply outline-none
}

.vc-highlight,
.vc-am-pm button.active {
    @apply !bg-primary-1;
}

.vc-time-date {
    @apply !hidden;
}

.dp__input{
    @apply bg-white border !border-secondary-2 focus:!border-red-600 !h-[50px] lg:!rounded-2xl xxs:rounded-[5px];
    @apply cursor-pointer transition-all w-full outline-none text-sm z-0;
}
.dp__range_end, .dp__range_start, .dp__active_date, .dp__overlay_cell_active{
    @apply !bg-primary-1 !text-white;
}
.dp__today{
    @apply !border-primary-1;
}


