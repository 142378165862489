.prefix__input {
    @apply z-10 lg:!w-[250px] xxs:w-[250px] !border-none lg:!min-h-[48px] xxs:min-h-[42px];
}
.multiselect__dropdown::-webkit-scrollbar {
    @apply w-[4px];
}
.multiselect__dropdown::-webkit-scrollbar-track {
    /* @apply bg-primary-3-400; */
}
.multiselect__dropdown::-webkit-scrollbar-thumb {
    @apply bg-primary-1-600 rounded-3xl;
}
