.permissions__table tr {
    @apply border-b border-primary-3-100/30;
}

.permissions__table tbody tr {
    @apply last:border-none;
}

.permissions__table td {
    @apply lg:!py-4 xxs:py-2 lg:!px-6 xxs:px-3 border-r border-primary-3-100/30 last:border-none lg:!text-base xxs:text-xs;
}

.permissions__table thead td {
    @apply lg:!text-xl xxs:text-xs lg:!text-left xxs:text-center font-medium;
}

.details-table tr td {
    @apply last:w-[70%] lg:first:!pr-0 xxs:first:!pr-3 pb-6 lg:!text-lg xxs:text-xs text-primary-3 align-top last:font-medium last:max-w-[200px] last:break-words;
}

.details-table tr {
    @apply w-full;
}
